body {
    background-color: #FCFAFA;
}

.report{
    background-color: rgb(223, 221, 221);
    border: 1px solid rgb(156, 156, 156);
    padding: 10px;
    border-radius: 3px;
    margin: 10px 0px;
    .report-title{
        font-size: 23px;
        font-weight: bold;
        color: rgb(39, 39, 39);
    }
    .report-download{
        background-color: #0CCA4A;
        border: none;
    }
    .report-date{
        font-size: 15px;

    }
}

/* Login form */
.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.login{
    margin-top: 30px;
    h1{
        text-align: center;
    }
    .login-form{
        background-color: rgb(223, 221, 221);
        border: 1px solid rgb(156, 156, 156);
        border-radius: 3px;
        padding: 15px;
    }
}

.client_list{
    ul{
        list-style: none;
        padding-left: 0px;
    }
    .client_item{
        padding: 8px;
        margin: 10px;
        background-color: rgb(223, 221, 221);
        border-radius: 3px;
        .company_name{
            font-size: 20px;
        }
        a{
            text-decoration: none;
            color: black;
        }
    }
}
